import { VueShowdownPlugin } from 'vue-showdown'
import { VueQueryPlugin } from '@tanstack/vue-query'
import head from './init/head'
import i18n from './init/i18n'
import store from './init/store'
import emitter from './init/emitter'
import router from './router'
import useComponents from './components'
import useFilter from './filter'
import useFeature from './feature'
import './init/echo'
import { useAcl } from '@tenant/composables/use-acl'

const useCore = (app) => {
    useFilter(app)
    useFeature(app)
    useComponents(app)

    app.config.globalProperties.emitter = emitter

    app.config.warnHandler = (msg, instance, trace) =>
        !['getCurrentInstance() called inside a computed getter.'].some(
            (warning) => msg.includes(warning)
        ) && console.warn('[Vue warn]: '.concat(msg).concat(trace))

    app.use(store)
    app.use(head)
    app.use(router)
    app.use(i18n)
    app.use(VueQueryPlugin)
    app.use(VueShowdownPlugin, {
        flavor: 'github',
    })
    app.provide('$feature', app.config.globalProperties.$feature)

    app.config.globalProperties.$acl = useAcl()

    return { router, store }
}

export { useCore }
